body {
  height: 100vh;
  width: 100vw;
  background-color: rgb(157, 246, 147);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parent {
  position: relative;
}

#logo {
  position: relative;
  animation: fill 0.5s ease forwards 3s;
  padding-top: 0;
  margin-top: 0;
}

#logo path {
  stroke: white;
}

#logo path:nth-child(2) {
  stroke-dasharray: 405.1066589355469;
  stroke-dashoffset: 405.1066589355469;
  animation: line-anim 2s ease forwards;
}

#logo path:nth-child(3) {
  stroke-dasharray: 259.2702331542969;
  stroke-dashoffset: 259.2702331542969;
  animation: line-anim 2s ease forwards 0.2s;
}

#logo path:nth-child(4) {
  stroke-dasharray: 221.40672302246094;
  stroke-dashoffset: 221.40672302246094;
  animation: line-anim 2s ease forwards 0.4s;
}

#logo path:nth-child(5) {
  stroke-dasharray: 329.2743225097656;
  stroke-dashoffset: 329.2743225097656;
  animation: line-anim 2s ease forwards 0.6s;
}

#logo path:nth-child(6) {
  stroke-dasharray: 259.2705078125;
  stroke-dashoffset: 259.2705078125;
  animation: line-anim 2s ease forwards 0.8s;
}

#logo path:nth-child(7) {
  stroke-dasharray: 221.4069366455078;
  stroke-dashoffset: 221.4069366455078;
  animation: line-anim 2s ease forwards 1s;
}

#logo path:nth-child(8) {
  stroke-dasharray: 290.60797119140625;
  stroke-dashoffset: 290.60797119140625;
  animation: line-anim 2s ease forwards 1.2s;
}

#logo path:nth-child(9) {
  stroke-dasharray: 482.23486328125;
  stroke-dashoffset: 482.23486328125;
  animation: line-anim 2s ease forwards 1.4s;
}

#logo path:nth-child(10) {
  stroke-dasharray: 405.10723876953125;
  stroke-dashoffset: 405.10723876953125;
  animation: line-anim 2s ease forwards 1.6s;
}

#logo path:nth-child(11) {
  stroke-dasharray: 356.0116271972656;
  stroke-dashoffset: 356.0116271972656;
  animation: line-anim 2s ease forwards 1.8s;
}

#logo path:nth-child(12) {
  stroke-dasharray: 290;
  stroke-dashoffset: 290;
  animation: line-anim 2s ease forwards 2s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: rgb(236, 126, 30);
  }
}

.cat-image {
  position: absolute;
  top: -95%;
  left: 50%;
  margin-bottom: 2rem;
  max-width: 80%;
}

.cat-image:hover {
  animation: jiggle 0.5s infinite;
}

.button-33 {
  display: block;
  background-color: rgb(24,147,255);
  border-radius: 100px;
  color: rgb(242, 234, 234);
  cursor: pointer;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 1.5rem 3rem;
  text-align: center;
  text-decoration: none;
  border: 0;
  font-size: 1.6rem;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 1rem;
  margin-left: 35%;
  font-weight: bold;
}

#loginButton {
  border: 4px solid white;
  width: 180px;
}

#signupButton {
  border: 4px solid white;
  width: 180px;
}

.button-33:hover {
  animation: jiggle 0.5s infinite;
}

#homepageButtonSection {
  background-color: rgb(248, 248, 127);
  margin-top: 3%;
  border: 5px white solid;
  border-radius: 10rem;
  padding-top: 1%;
  padding-bottom: 3%;
}

.para-home-page {
  position: absolute;
  font-size: larger;
  border-left: 20px rgb(24,147,255) solid;
  border-right: 20px rgb(24,147,255) solid;
  background-color: rgb(241, 154, 79);
  color: white;
  border-radius: 15px;
  bottom: -400px;
  left: 350px;
  max-width: 750px;
  max-height: 830px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}



@keyframes jiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.newUser {
  margin-top: auto;
  margin-left: 43%;
}

@media (max-width: 1189px) {
  .para-home-page {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(24,147,255) solid;
    border-right: 20px rgb(24,147,255) solid;
    background-color: rgb(241, 154, 79);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 230px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 1100px) {
  .para-home-page {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(24,147,255) solid;
    border-right: 20px rgb(24,147,255) solid;
    background-color: rgb(241, 154, 79);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 180px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 1000px) {
  .para-home-page {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(24,147,255) solid;
    border-right: 20px rgb(24,147,255) solid;
    background-color: rgb(241, 154, 79);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 110px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .para-home-page {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(24,147,255) solid;
    border-right: 20px rgb(24,147,255) solid;
    background-color: rgb(241, 154, 79);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 50px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 780px) {
  .para-home-page {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(24,147,255) solid;
    border-right: 20px rgb(24,147,255) solid;
    background-color: rgb(241, 154, 79);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 20px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}
@media (max-width: 600px) {
  .cat-image {
    top: -95%;
  }

  .button-33 {
    margin-left: 10%;
  }

  .newUser {
    margin-left: 20%;
  }

}

@media (max-width: 480px) {
  .cat-image {
    top: 5%;
  }

  .button-33 {
    margin-left: 5%;
  }

  .newUser {
    margin-left: 15%;
  }
}

#loginModalHeader {
  background-color: rgb(24,147,255);
}

#loginModalBody {
  background-color: rgb(248, 248, 127);
}

#signupModalHeader {
  background-color: rgb(24,147,255);
}

#signupModalBody {
  background-color: rgb(248, 248, 127);
}

@media (max-width: 1189px) {
  .para-left {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(151, 32, 32) solid;
    border-right: 20px rgb(151, 32, 32) solid;
    background-color: rgb(0, 80, 0);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 250px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 1088px) {
  .para-left {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(151, 32, 32) solid;
    border-right: 20px rgb(151, 32, 32) solid;
    background-color: rgb(0, 80, 0);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 180px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 988px) {
  .para-left {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(151, 32, 32) solid;
    border-right: 20px rgb(151, 32, 32) solid;
    background-color: rgb(0, 80, 0);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 120px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 988px) {
  .para-left {
    position: absolute;
    font-size: larger;
    border-left: 20px rgb(151, 32, 32) solid;
    border-right: 20px rgb(151, 32, 32) solid;
    background-color: rgb(0, 80, 0);
    color: white;
    border-radius: 15px;
    bottom: -400px;
    left: 50px;
    max-width: 750px;
    max-height: 830px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}
