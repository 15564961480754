body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 100%;
  max-width: 500px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #555;
}

.form-group select {
  height: 40px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #61dafb;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #5bc0de;
}

.task {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.task-title {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.task-description {
  width: 100%;
}

.task-details {
  margin-top: 10px;
  color: #888;
}

.task-actions {
  margin-top: 15px;
  text-align: right;
}

.task-actions button {
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: #61dafb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.task-actions button:hover {
  background-color: #5bc0de;
}

.completed {
  text-decoration: line-through;
  color: #888;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}