.pet-card {
  max-width: 700px;
  min-width: 500px;
  max-height: 180vh;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 10px;
}

.pet-label {
  color: white;
  font-size: x-large;
  margin-left: 90px;
}

#image {
  width: 150px;
}

.selected {
  width: 150px;
  border-radius: 20px;
  background-color: rgb(160, 212, 255);
}

.pet-picker-header {
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: xx-large;
}

.pet-container {
  background: url("../images/petstores.jpeg") center center/cover no-repeat;
}
