.mainTaskDiv {
    background-color: rgb(157, 246, 147);
    height: 100vh;
    width: 100vw;
}
    
#taskSection {
    background-color: rgb(220, 222, 222);
    border: 3.5px solid white;
    margin-right: 67%;
    border-radius: 1rem;
    background-color: rgb(248, 248, 127);
    margin-left: 0.8%;
    width: 50%;
}
    
#taskPageTitle { 
    color: rgb(236, 126, 30);
    font-size: 60px;
    text-shadow: -2.5px 0 white, 0 2.5px white, 2.5px 0 white, 0 -2.5px white;
}
    