.petHouse {
  width: 220px;
  height: 200px;
}

.foodBowl {
  margin-top: 190px;
  width: 60px;
  height: 40px;
}

.waterBowl {
  margin-top: 170px;
  width: 60px;
  height: 60px;
}

.mainPetPicker {
  height: 400px;
  top: 200px;
}

.petty {
  margin-top: 80px;
  width: 150px;
  animation: slide 6.6s linear infinite;
  animation-play-state: paused;
}

.petty-move {
  animation: slide 6.6s linear infinite;
  margin-top: 80px;
  width: 150px;
  animation-play-state: running;
}

.pet-dec-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 350px;
  padding-right: 20px;
}

.pet-dec-card-hide {
  display: none;
}

.cemetery {
  width: 250px;
}

.graveyard-button {
  position: relative;
  font-size: larger;
  top: 110px;
  left: 150px;
  background-color: black;
  border: 1px black solid;
  border-radius: 15px;
}

.graveyard-button-hide {
  display: none;
}

.graveyard-button:hover {
  background-color: darkblue;
  box-shadow: 0 0 0 1px darkblue;
}

.button-card {
  background-color: black;
  border-radius: 15px;
  position: relative;
  font-size: 22px;
  top: 110px;
  left: 400px;
}

.button-card-hide {
  display: none;
}

.button-card-indiv {
  background-color: black;
  border-radius: 15px;
  position: relative;
  font-size: 22px;
  top: 110px;
  left: 250px;
}

.button-card:hover {
  background-color: darkblue;
  box-shadow: 0 0 0 2px darkblue;
}

.pet-modal {
  background-color: transparent;
  display: flex;
  left: 500px;
  width: 500px;
  border-radius: 22px;
}

.pet-title {
  margin: auto;
  width: 50%;
  padding: 10px;
  font-weight: bolder;
  color: white;
}

.pet-title-hide {
  display: none;
}

.grave {
  position: relative;
  right: 340px;
  top: 140px;
  width: 70px;
  height: 70px;
}

.graveyard-holder {
  position: relative;
  border-radius: 15px;
  width: 50vw;
  max-height: 55vh;
  padding: 10px;
  bottom: 120px;
  left: 600px;
}

.main-background-div {
  background: url("../images/tinyhouse.jpeg") center center/cover no-repeat;
  height: 100vh;
  width: 100vw;
  /* align-items: center; */
  flex-direction: column;
  box-shadow: inset 0 0 0 10px;
  object-fit: contain;
  resize: both;
  overflow: auto;
}

input {
  position: relative;
}

.handle-pet-btn {
  color: rgb(16, 46, 117);
  font-size: x-large;
  font-weight: bold;
}

.jump-button {
  background-color: black;
  border-radius: 15px;
  position: absolute;
  left: 250px;
  top: 400px;
}

.jump-button-hide {
  display: none;
}

.jump-button:hover {
  background-color: darkblue;
  box-shadow: 0 0 0 1px darkblue;
}

.create-pet-div {
  background-color: transparent;
}

.create-pet-div-hide {
  display: none;
}

.health-bar {
  width: 200px;
  height: 20px;
  background-color: #eee;
  border: 1px solid #000;
}

.health-bar-inner {
  height: 100%;
  background-color: red;
  transition: width 0.3s ease-in-out;
}

@media (min-width: 1920px) {
  .pet-dec-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 460px;
    padding-right: 40px;
  }
  .petty {
    margin-top: 120px;
    width: 230px;
  }

  .petHouse {
    width: 320px;
    height: 300px;
  }

  .foodBowl {
    margin-top: 250px;
    width: 95px;
    height: 65px;
  }

  .waterBowl {
    margin-top: 240px;
    width: 95px;
    height: 95px;
  }

  .button-card {
    background-color: black;
    border-radius: 15px;
    position: relative;
    font-size: 22px;
    top: 170px;
    left: 400px;
  }

  .graveyard-button {
    position: relative;
    font-size: larger;
    top: 170px;
    right: 240px;
    background-color: black;
    border: 1px black solid;
    border-radius: 15px;
  }
}

@media (max-width: 912px) {
  .pet-dec-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 810px;
    padding-right: 20px;
  }
  .petty {
    width: 230px;
  }

  .petHouse {
    width: 280px;
    height: 300px;
  }

  .foodBowl {
    margin-top: 120px;
    width: 95px;
    height: 65px;
  }

  .waterBowl {
    margin-top: 70px;
    width: 95px;
    height: 95px;
  }
}

@media (max-width: 390px) {
  .pet-dec-card {
    display: flex;
    flex-direction: row;
    padding-right: 30px;
    margin-top: 280px;
    margin-left: 20px;
    width: 390px;
  }

  .petty {
    margin-bottom: 80px;
    width: 135px;
  }

  .petHouse {
    margin-top: 100px;
    width: 150px;
    height: 130px;
  }

  .foodBowl {
    margin-top: 130px;
    width: 35px;
    height: 35px;
  }

  .waterBowl {
    margin-top: 120px;
    width: 45px;
    height: 45px;
  }
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  2% {
    transform: translate(-40px, -50px);
  }

  4% {
    transform: translate(-80px, -50px);
  }
  6% {
    transform: translate(-120px, 0);
  }
  8% {
    transform: translate(-160px, -50px);
  }
  10% {
    transform: translate(-200px, 0);
  }
  12% {
    transform: translate(-240px, -50px);
  }
  14% {
    transform: translate(-280px, 0);
  }
  16% {
    transform: translate(-320px, -50px);
  }
  18% {
    transform: translate(-360px, 0);
  }
  20% {
    transform: translate(-400px, -50px);
  }
  22% {
    transform: translate(-440px, 0);
  }
  24% {
    transform: translate(-480px, -50px);
  }
  26% {
    transform: translate(-520px, 0);
  }
  28% {
    transform: translate(-560px, -50px);
  }
  30% {
    transform: translate(-600px, 0);
  }
  32% {
    transform: translate(-640px, 50px);
  }
  34% {
    transform: translate(-680px, 0);
  }
  36% {
    transform: translate(-720px, -50px);
  }
  38% {
    transform: translate(-760px, 0);
  }
  40% {
    transform: translate(-800px, -50px);
  }
  42% {
    transform: translate(-760px, 0);
  }
  44% {
    transform: translate(-720px, -50px);
  }
  46% {
    transform: translate(-680px, 0);
  }
  48% {
    transform: translate(-720px, -50px);
  }
  50% {
    transform: translate(-760px, 0);
  }
  52% {
    transform: translate(-800px, -50px);
  }
  54% {
    transform: translate(-840px, 0);
  }
  56% {
    transform: translate(-800px, -50px);
  }
  58% {
    transform: translate(-760px, 0);
  }
  60% {
    transform: translate(-720px, -50px);
  }
  62% {
    transform: translate(-680px, 0);
  }
  64% {
    transform: translate(-640px, -50px);
  }
  66% {
    transform: translate(-600px, 0);
  }
  68% {
    transform: translate(-560px, -50px);
  }
  70% {
    transform: translate(-520px, 0);
  }
  72% {
    transform: translate(-480px, -50px);
  }
  74% {
    transform: translate(-520px, 0);
  }
  76% {
    transform: translate(-480px, -50px);
  }
  78% {
    transform: translate(-440px, 0);
  }
  80% {
    transform: translate(-400px, -50px);
  }
  82% {
    transform: translate(-360px, 0);
  }
  84% {
    transform: translate(-320px, -50px);
  }
  86% {
    transform: translate(-280px, 0);
  }
  88% {
    transform: translate(-240px, -50px);
  }
  90% {
    transform: translate(-200px, 0);
  }
  92% {
    transform: translate(-160px, -500px);
  }
  94% {
    transform: translate(-120px, -520px);
  }
  96% {
    transform: translate(-80px, -540px);
  }

  98% {
    transform: translate(-40px, -510px) rotate(360deg);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* @keyframes move {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-600px);
  }
} */
/* from{ transform: translate(0,0)}
  to{ transform: translate(-600px, -100px)} */
