/* Navbar.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container {
    width: 100%;
    max-width: 500px;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #171a1b;
    padding: 10px;
  }
  
  .title {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
  
  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    margin-right: 10px;
  }
  
  .link {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #5bc0de;
  }
  
  /* .link.active {
    color: #5bc0de;
  } */
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #555;
  }
  
  .form-group select {
    height: 40px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
 
  